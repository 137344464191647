import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';

// Hook para manejar los carritos
const useCart = () => {

    const initializeCart = () => ({
        cart: [],
        allow2x1: true,
        discount: 0,
        size: 0,
        originalTotal: 0,
        finalTotal: 0,
    });

    const initCarts = () => {
        const storedCarts = JSON.parse(localStorage.getItem('cartsData')) ?? [initializeCart()]
        return storedCarts.length > 0 ? storedCarts : [initializeCart()];
    }

    const initCartIndex = () => {
        const storedCartIndex = parseInt(localStorage.getItem('cartIndex')) ?? 0
        return ((carts.length - 1) <= storedCartIndex)  ? storedCartIndex : 0;
    }

    const [carts, setCarts] = useState(initCarts());
    const [cartIndex, setCartIndex] = useState(initCartIndex());

    const [currentCart, setCurrentCart] = useState(carts[cartIndex]);

    useEffect(() => setCurrentCart(carts[cartIndex]), [cartIndex, carts]);

    // Función para actualizar el tamaño del carrito actual
    const updateCartSize = (updatedCarts) => {
        updatedCarts[cartIndex].size = updatedCarts[cartIndex].cart.reduce((total, item) => total + item.amount, 0);
    };

    // Función para calcular y actualizar los totales del carrito
    const updateCartTotals = (updatedCarts) => {
        const cart = updatedCarts[cartIndex];

        if (cart.cart.length > 0) {
            const originalTotal = cart.cart.reduce(
                (acc, item) => acc + Number(item.product.price) * item.amount, 0
            );

            if (cart.size === 2 && cart.allow2x1) {
                const maxPrice = cart.cart.reduce(
                    (max, item) => Math.max(max, Number(item.product.price)), 0
                );
                cart.finalTotal = maxPrice;
            } else {
                const discountAmount = originalTotal * cart.discount / 100;
                cart.finalTotal = originalTotal - discountAmount;
            }

            cart.originalTotal = originalTotal;
        } else {
            cart.originalTotal = 0;
            cart.finalTotal = 0;
        }
    };

    // Función para agregar un carrito
    const addCart = () => {
        setCarts((prevCarts) => [...prevCarts, initializeCart()]);
    };

    // Función para eliminar el carrito actual
    const removeCart = () => {
        // Se filtra el arreglo eliminando el carrito en el índice actual (cartIndex)
        const updatedCarts = carts.filter((_, index) => index !== cartIndex);

        // Si no quedan carritos, se crea uno nuevo y se reinicia el índice a 0
        if (updatedCarts.length === 0) {
            updatedCarts.push(initializeCart());
            setCartIndex(0);
        } else if (cartIndex >= updatedCarts.length) {
            // Si el carrito actual era el último, se ajusta el índice al nuevo último elemento
            setCartIndex(updatedCarts.length - 1);
        }

        // Se actualiza el estado de carritos
        setCarts(updatedCarts);
    };

    // Función para vaciar el carrito actual con confirmación
    const emptyCart = () => {
        confirmAlert({
            customUI: ({ onClose }) => (
                <div className="overlay">
                    <div className="custom-ui">
                        <h1>Confirmación</h1>
                        <p>¿Desea eliminar todos los productos del carrito?</p>
                        <div className="custom-ui-buttons">
                            <button onClick={onClose}>Cancelar</button>
                            <button
                                onClick={() => {
                                    const updatedCarts = [...carts];
                                    updatedCarts[cartIndex] = initializeCart();
                                    updateCartSize(updatedCarts);
                                    updateCartTotals(updatedCarts);
                                    setCarts(updatedCarts);
                                    toast.success('Carrito vaciado', { hideProgressBar: true, autoClose: 1000 });
                                    onClose();
                                }}
                            >
                                Eliminar
                            </button>
                        </div>
                    </div>
                </div>
            ),
        });
    };

    // Guardar en localStorage cada que se haga un cambio a "carts"
    useEffect(() => {
        localStorage.setItem('cartsData', JSON.stringify(carts));
    }, [carts]);

    // Guardar en localStorage cada que se haga un cambio a "cartIndex"
    useEffect(() => {
        localStorage.setItem('cartIndex', cartIndex);
    }, [cartIndex]);

    // Función para agregar un producto a un carrito
    const addProductToCart = (product) => {
        const updatedCarts = [...carts];
        const currentCart = updatedCarts[cartIndex];
        const productIndex = currentCart.cart.findIndex(item => item.product.product_id === product.product_id);
    
        if (productIndex !== -1) {
            if (currentCart.cart[productIndex].amount < product.stock) {
                currentCart.cart[productIndex].amount += 1;
            } else {
                toast.info('Límite del stock alcanzado', { autoClose: 1000, hideProgressBar: true });
            }
        } else {
            currentCart.cart.unshift({ product, amount: 1 });
        }
    
        updateCartSize(updatedCarts);
    
        // Verifica si hay más de 2 artículos y el descuento es 0, entonces lo cambia a 50
        if (currentCart.size > 2 && currentCart.discount === 0) {
            currentCart.discount = 50;
        }
    
        updateCartTotals(updatedCarts);
        setCarts(updatedCarts);
    };    

    // Función para restar un producto de un carrito
    const subtractProductFromCart = (product) => {
        const updatedCarts = [...carts];
        const currentCart = updatedCarts[cartIndex];
        const productIndex = currentCart.cart.findIndex(item => item.product.product_id === product.product_id);

        if (productIndex !== -1) {
            if (currentCart.cart[productIndex].amount > 1) {
                currentCart.cart[productIndex].amount -= 1;
            } else {
                currentCart.cart.splice(productIndex, 1);
            }
        }

        updateCartSize(updatedCarts);
        updateCartTotals(updatedCarts);
        setCarts(updatedCarts);
    };

    // Función para remover un producto de un carrito
    const removeProductFromCart = (product) => {
        const updatedCarts = [...carts];
        const currentCart = updatedCarts[cartIndex];
        const productIndex = currentCart.cart.findIndex(item => item.product.product_id === product.product_id);

        if (productIndex !== -1) {
            currentCart.cart.splice(productIndex, 1);
        }

        updateCartSize(updatedCarts);
        updateCartTotals(updatedCarts);
        setCarts(updatedCarts);
    };

    // Función para verificar si aplica la promoción 2x1
    const is2x1Applicable = () => {
        const currentCart = carts[cartIndex];
        // Calcula la cantidad total de unidades en el carrito
        const totalUnits = currentCart.cart.reduce((total, item) => total + item.amount, 0);
        return totalUnits === 2 && currentCart.allow2x1;
    };

    // Función para alternar el valor de allow2x1 en el carrito actual
    const toggleAllow2x1 = () => {
        const updatedCarts = [...carts];
        updatedCarts[cartIndex].allow2x1 = !updatedCarts[cartIndex].allow2x1;
        updateCartTotals(updatedCarts);
        setCarts(updatedCarts);
        toast.success(`Promoción 2x1 ${updatedCarts[cartIndex].allow2x1 ? "activada" : "cancelada"}`, { hideProgressBar: true, autoClose: 1000 });
    };

    // Función para actualizar el descuento en el carrito actual
    const updateCartDiscount = (newDiscount) => {
        const validDiscount = Math.max(0, Math.min(100, newDiscount));
        const updatedCarts = [...carts];
        updatedCarts[cartIndex].discount = validDiscount;
        updateCartTotals(updatedCarts);
        setCarts(updatedCarts);
    };

    return {
        carts,
        cartIndex,
        currentCart,
        addCart,
        removeCart,
        emptyCart,
        setCartIndex,
        addProductToCart,
        subtractProductFromCart,
        removeProductFromCart,
        is2x1Applicable,
        toggleAllow2x1,
        updateCartDiscount,
    };
};

export default useCart;