import UtilityContainer from '../UtilityContainer/UtilityContainer';
import React, { useState, useEffect } from 'react';
import BACK_END from '../../../config/direction';
import { BarChart } from '@mui/x-charts';
import Paper from "@mui/material/Paper";
import Dropdown from 'react-dropdown';
import axios from 'axios';
import { exportToExcel } from '../../../util/excel';

import './UtilityMonthly.css'

import { useContext } from 'react';
import { SessionContext } from '../../../hooks/useSession';

const UtilityMonthly = () => {
    const { token } = useContext(SessionContext);
    
    const options = ['Ganancias', 'Ventas', 'Facturación'];
    const [selectedOption, setSelectedOption] = useState({ label: options[0], value: options[0] });

    const [dataset, setDataset] = useState([]);

    const months = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
    ];

    // Initial fetch
    const getData = () => {
        const getTimezoneOffset = () => {
            const offset = new Date().getTimezoneOffset();
            const absoluteOffset = Math.abs(offset);
            const hours = Math.floor(absoluteOffset / 60);
            const minutes = absoluteOffset % 60;
            const sign = offset <= 0 ? '+' : '-';
            return `${sign}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
        };
    
        const userTimezone = getTimezoneOffset();
        
        axios.get(`${BACK_END}/stadistics/sales-by-month?monthsAgo=5&timezone=${userTimezone}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
            .then((res) => {
                const data = res?.data;
                if (res.status === 200) {
                    const monthlyData = Object.values(data).map(value => value || {});

                    const yChart = monthlyData.map((monthData, index) => {
                        let monthName = '';

                        if (monthData.month !== undefined) {
                            const dateParts = monthData.month.split('-');
                            const monthIndex = parseInt(dateParts[1], 10) - 1;
                            monthName = months[monthIndex];
                        }

                        return {
                            gain: monthData?.totalProfit || 0,
                            sales: monthData?.totalSales || 0,
                            totalProducts: monthData?.totalProductsSold || 0,
                            month: monthName,
                        }
                    });

                    setDataset(yChart);
                }
            })
            .catch((error) => console.error(error));
    }

    // eslint-disable-next-line
    useEffect(getData, []);

    const handleExportClick = () => {
        let data = [];
        
        dataset.forEach(row => {
            if(row.month !== '') {
                data.push({ 'Mes': row?.month, 'Total de Ventas': Number(row?.sales), 'Ganancias': Number(row?.gain), 'Numero de ventas': Number(row?.totalProducts) });
            }
        });
        
        exportToExcel(data, 'Ventas por mes');
    };

    // Container header
    const header = (
        <div className='utility-header'>
            <Dropdown
                className='utility-scroll'
                options={options}
                onChange={(text) => setSelectedOption(text)}
                value={selectedOption}
                placeholder='Ganancia'
            />
            por mes
            
        </div>
    );

    // Custom Tooltip
    const CustomItemTooltipContent = (props) => {
        const { series, dataIndex, axisValue } = props;
        return (
            <Paper sx={{ padding: 1, backgroundColor: 'white' }}>
                <p>{axisValue}</p>
                {
                    (selectedOption.value === 'Facturación')
                        ?
                        <p>{series[0]?.data[dataIndex] || 'N/A'}</p>
                        :
                        <p>$ {series[0]?.data[dataIndex]?.toFixed(2) || 'N/A'}</p>
                }
            </Paper>
        );
    };

    const valueFormatter = (element) => (selectedOption.value !== 'Facturación') ? `$${element.toFixed(0)}` : `${element.toFixed(0)}`;

    const getKey = () => {
        let result = 'gain';
        
        if(selectedOption.value === 'Ventas') {
            result = 'sales';
        } else if(selectedOption.value === 'Facturación') {
            result = 'totalProducts';
        }

        return result;
    }

    const chart = (
        <div className='utility-monthly-content chart-content-father'>
            {
                (dataset.length > 0)
                    ?
                    <BarChart
                        sx={{ width: "100%", maxWidth: 580, maxHeight: 300 }}
                        xAxis={[
                            {
                                scaleType: 'band',
                                label: 'Mes',
                                dataKey: 'month',
                                tick: {
                                    angle: 45,
                                    textAnchor: 'middle',
                                }
                            }
                        ]}
                        yAxis={[
                            {
                                valueFormatter: (element) => (selectedOption.value !== 'Facturación') ? `$${element?.toFixed(0)}` : `${element?.toFixed(0)}`,
                            },
                        ]}
                        margin={{
                            left: 60,
                            right: 20,
                        }}
                        dataset={dataset}
                        series={[{ dataKey: getKey(), valueFormatter }]}
                        //width={600}
                        height={300}
                        layout="vertical"
                        tooltip={{
                            trigger: "axis",
                            axisContent: CustomItemTooltipContent,
                        }}
                    />
                    :
                    <></>
            }
        </div>
    );

    return (
        <UtilityContainer
            header={header}
            content={chart}
            handleExportClick={handleExportClick}
        />
    )
}

export default UtilityMonthly;