import { BsTrashFill, BsFillCartXFill } from 'react-icons/bs';
import { FaPlus, FaMinus, FaKey } from 'react-icons/fa6';
import { SessionContext } from '../../hooks/useSession';
import React from 'react';
import { FaMoneyBill } from 'react-icons/fa';
//import { GiBackForth } from "react-icons/gi";
import { toast } from 'react-toastify';
import { useContext } from 'react';
import './Receipt.css';
import { MdCancel } from "react-icons/md";

import logo from '../../assets/logo.png';
import { openCashBox } from '../../util/pos_esc';
import { formatNumber } from '../../util/formatter';

const Receipt = ({ setPayingState, stage }) => {

   const { userData, carts, cartIndex, setCartIndex, currentCart, removeCart, addCart, updateCartDiscount, is2x1Applicable, emptyCart, addProductToCart, removeProductFromCart, subtractProductFromCart, toggleAllow2x1 } = useContext(SessionContext);

   const isAdmin = userData && userData.rol === "Administrador";

   const currentDate = new Date();
   const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
   let dateDisplay = currentDate.toLocaleDateString('es-MX', options);
   dateDisplay = dateDisplay.charAt(0).toUpperCase() + dateDisplay.slice(1, -8);

   const changePayState = () => {
      if (currentCart.size > 0) {
         setPayingState(1);
      } else {
         toast.info('Carrito vacío', { autoClose: 2000, hideProgressBar: true });
      }
   }

   const increaseDiscount = () => {
      if (currentCart.discount < 100) {
         if (currentCart.discount + 5 > 100) {
            updateCartDiscount(100);
         } else {
            updateCartDiscount(currentCart.discount + 5);
         }
      }
   }

   const decreaseDiscount = () => {
      if (currentCart.discount > 0) {
         if (currentCart.discount - 5 < 0) {
            updateCartDiscount(0);
         } else {
            updateCartDiscount(currentCart.discount - 5);
         }
      }
   }

   const handleDeleteCart = (event) => {
      event.stopPropagation();
      if(carts.length > 1) {
         removeCart();
      }
   }

   return (
      <div className='ticket-card'>
         <div className='receipt-header'>
            {
               carts.map((cart, index) => (
                  <div
                     key={`ticket_${index}`}
                     className={`receipt-header-ticket ${index === cartIndex && 'receipt-header-ticket-selected'}`}
                     onClick={() => setCartIndex(index)}
                  >
                     {
                        (index === cartIndex) ?
                           <div className='receipt-header-ticket-current'>
                              <p className='receipt-header-ticket-current-text'>{`Ticket ${index + 1}`}</p>
                              <MdCancel
                                 size={26}
                                 className='receipt-header-ticket-delete'
                                 onClick={handleDeleteCart}
                              />
                           </div>
                           :
                           (index + 1)
                     }
                  </div>
               ))
            }
            {carts.length < 6 && <div className="receipt-header-ticket receipt-header-ticket-add" onClick={addCart}>+</div>}
         </div>
         <div className='products-list'>
            <div className='pay-box'>
               <div className='header'>
                  <img src={logo} alt='logo' className="img-logo" />
                  <p className='sales-date'>{dateDisplay}</p>
               </div>

               <div className={stage === 0 ? 'products-box' : 'products-box-checkout'}>
                  {
                     (currentCart.size <= 1)
                        ?
                        currentCart.cart.map((item, index) =>
                           <div
                              className='sales-product-column'
                              key={`cart_column_${index}`}
                           >
                              <div className='recipent-header-row recipent-header-margin-left'>
                                 <p className='recipent-section-header'>{currentCart.discount}% DESCUENTO</p>
                                 <div className='sales-button-row'>
                                    <button className='sale-pad-button' onClick={increaseDiscount}>
                                       <FaPlus size={16} color='black' />
                                    </button>
                                    <button className='sale-pad-button' onClick={decreaseDiscount}>
                                       <FaMinus size={16} color='black' />
                                    </button>
                                 </div>
                              </div>
                              <div className='sales-product-row'>
                                 <p className='juguete'>{item.product.name}</p>
                                 <p className='juguete' >({item.amount})</p>
                                 <span className='sales-dot-separator'></span>
                                 <p className='juguete' >${item.product.price}</p>
                              </div>
                              <div className='sales-product-row'>
                                 <div className='sales-button-row'>
                                    <button className='sale-pad-button' onClick={() => addProductToCart(item.product)}>
                                       <FaPlus size={16} color='black' />
                                    </button>
                                    <button className='sale-pad-button' onClick={() => subtractProductFromCart(item.product)}>
                                       <FaMinus size={16} color='black' />
                                    </button>
                                    <button className='sale-pad-button' onClick={() => removeProductFromCart(item.product)}>
                                       <BsTrashFill size={16} color='black' />
                                    </button>
                                 </div>
                              </div>
                           </div>
                        )
                        :
                        <>
                           {
                              (is2x1Applicable())
                                 ?
                                 <div className='recipent-section'>
                                    {
                                       (currentCart.allow2x1) ?
                                          <div className='recipent-header-row'>
                                             <p className='recipent-section-header'>DOSX1</p>
                                             {currentCart.size === 2 && <button className='recipent-cancel2x1' onClick={toggleAllow2x1}>Cancelar 2x1</button>}
                                          </div>
                                          :
                                          <div className='recipent-header-row'>
                                             <p className='recipent-section-header'>{currentCart.discount}% DESCUENTO</p>
                                             <div className='sales-button-row'>
                                                <button className='sale-pad-button' onClick={increaseDiscount}>
                                                   <FaPlus size={16} color='black' />
                                                </button>
                                                <button className='sale-pad-button' onClick={decreaseDiscount}>
                                                   <FaMinus size={16} color='black' />
                                                </button>
                                             </div>
                                             {currentCart.size === 2 && <button className='recipent-cancel2x1' onClick={toggleAllow2x1}>Activar 2x1</button>}
                                          </div>
                                    }
                                    <div className='product-2x1 background-animation'>
                                       {
                                          currentCart.cart.map((item, index) =>
                                             <div
                                                className='sales-product-column'
                                                key={`DOSX1_${index}`}
                                             >
                                                <div className='sales-product-row'>
                                                   <p className='juguete'>{item.product.name}</p>
                                                   <p className='juguete' >({item.amount})</p>
                                                   <span className='sales-dot-separator'></span>
                                                   <p className='juguete' >${item.product.price}</p>
                                                </div>
                                                <div className='sales-product-row'>
                                                   <div className='sales-button-row'>
                                                      <button className='sale-pad-button' onClick={() => addProductToCart(item.product)}>
                                                         <FaPlus size={16} color='black' />
                                                      </button>
                                                      <button className='sale-pad-button' onClick={() => subtractProductFromCart(item.product)}>
                                                         <FaMinus size={16} color='black' />
                                                      </button>
                                                      <button className='sale-pad-button' onClick={() => removeProductFromCart(item.product)}>
                                                         <BsTrashFill size={16} color='black' />
                                                      </button>
                                                   </div>
                                                </div>
                                             </div>
                                          )
                                       }
                                    </div>
                                 </div>
                                 :
                                 <div className='recipent-section'>
                                    <div className='recipent-header-row'>
                                       <p className='recipent-section-header'>{currentCart.discount}% DESCUENTO</p>
                                       <div className='sales-button-row'>
                                          <button className='sale-pad-button sales-pad-button-offer' onClick={increaseDiscount}>
                                             <FaPlus size={16} color='black' />
                                          </button>
                                          <button className='sale-pad-button sales-pad-button-offer' onClick={decreaseDiscount}>
                                             <FaMinus size={16} color='black' />
                                          </button>
                                       </div>
                                       {currentCart.size === 2 && <button className='recipent-cancel2x1' onClick={toggleAllow2x1}>Activar 2x1</button>}
                                    </div>
                                    <div className='product-discount'>
                                       {
                                          currentCart.cart.map((item, index) =>
                                             <div
                                                className='sales-product-column'
                                                key={`cart_item_${index}`}
                                             >
                                                <div className='sales-product-row'>
                                                   <p className='juguete'>{item.product.name}</p>
                                                   <p className='juguete' >({item.amount})</p>
                                                   <span className='sales-dot-separator'></span>
                                                   <p className='juguete' >${item.product.price}</p>
                                                </div>
                                                <div className='sales-product-row'>
                                                   <div className='sales-button-row'>
                                                      <button className='sale-pad-button' onClick={() => addProductToCart(item.product)}>
                                                         <FaPlus size={16} color='black' />
                                                      </button>
                                                      <button className='sale-pad-button' onClick={() => subtractProductFromCart(item.product)}>
                                                         <FaMinus size={16} color='black' />
                                                      </button>
                                                      <button className='sale-pad-button' onClick={() => removeProductFromCart(item.product)}>
                                                         <BsTrashFill size={16} color='black' />
                                                      </button>
                                                   </div>
                                                </div>
                                             </div>
                                          )
                                       }
                                    </div>
                                 </div>
                           }
                        </>
                  }
               </div>

            </div>

            <div className='receipt-total-container'>
               <div className='row-subtotal'>
                  <h4>Subtotal: ${formatNumber(currentCart.originalTotal)}</h4>
               </div>
               {
                  (stage === 0 && currentCart.size > 0)
                  &&
                  <button className='empty-cart-button' onClick={emptyCart}>
                     <BsFillCartXFill className="empty-cart-icon" />
                  </button>
               }
               <div className='row-total'>
                  <h2>Total: </h2>
                  <div className='row-total-money'>
                     <h2>${formatNumber(currentCart.finalTotal)}</h2>
                  </div>
               </div>
            </div>

         </div>

         <div className='receipt-button-row'>
            {/*stage === 0 &&
               <button className='receipt-cash-box-button receipt-cash-box-button--hover-shadow' onClick={openModal}>
                  <GiBackForth size={24} color='000000' />
               </button>
            */}
            {stage === 0 &&
               <button className='charge charge--hover-shadow golden-button' onClick={changePayState}>
                  <FaMoneyBill size={38} color='000000' className='charge-icon' />
                  Cobrar
               </button>
            }
            {(isAdmin && stage === 0) ?
               <button className='receipt-cash-box-button receipt-cash-box-button--hover-shadow' onClick={openCashBox}>
                  <FaKey size={32} color='000000' />
               </button>
               :
               <></>
            }
         </div>
      </div>
   )
}

export default Receipt;