import Navbar from '../../components/Navbar/Navbar';
import React, { useContext, useEffect, useState } from 'react';
import BACK_END from '../../config/direction';
import Register from '../Register/Register';
import { FaSearch } from 'react-icons/fa';
import Receipt from '../Receipt/Receipt';
import { toast } from 'react-toastify';
import Modal from 'react-modal';

import './Sales.css';
import '../../assets/shadows/shadow.css';

//import { useContext } from 'react';
//import { SessionContext } from '../../hooks/useSession';
import { FaMoneyBillTransfer } from 'react-icons/fa6';
import CashDepositModal from '../../components/CashDepositModal/CashDepositModal';
import CashWithdrawalModal from '../../components/CashWithdrawalModal/CashWithdrawalModal';
import { SessionContext } from '../../hooks/useSession';

const Sales = () => {

  Modal.setAppElement('#root');

  const { addProductToCart } = useContext(SessionContext);

  const [products, setProducts] = useState([]);
  const [searchedProduct, setSearchedProduct] = useState('');

  const [isCashWithdrawalModalOpen, setIsCashWithdrawalModalOpen] = useState(false);
  const [isCashDepositModalOpen, setIsCashDepositModalOpen] = useState(false);

  const openCashWithdrawalModal = () => {
    setIsCashWithdrawalModalOpen(true);
  };

  const closeCashWithdrawalModal = () => {
    setIsCashWithdrawalModalOpen(false);
  };

  const openCashDepositModal = () => {
    setIsCashDepositModalOpen(true);
  };

  const closeCashDepositModal = () => {
    setIsCashDepositModalOpen(false);
  };

  // 0 is for initial-stage (adding products) and 1 is for final-stage (paying products)
  const [payingState, setPayingState] = useState(0);

  // Get a few products from the database
  const getProducts = () => {
    fetch(`${BACK_END}/product/0`)
      .then(async (response) => {
        if (response.ok) {
          const data = await response.json();
          setProducts(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // Get searched products
  const getSearchProduct = () => {
    fetch(`${BACK_END}/product/filter/0`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: searchedProduct,
      }),
    })
      .then(async (response) => {
        if (response.ok) {
          const data = await response.json();
          //console.log(data)
          setProducts(data);
        } else {
          throw new Error('Error en la solicitud');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const afterSell = () => {
    getSearchProduct();
  }

  // Add a searched product
  const addSearchedProductToCart = async () => {
    let products = [];
    await fetch(`${BACK_END}/product/filter/0`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: searchedProduct.trim(),
      }),
    })
      .then(async (response) => {
        if (response.ok) {
          const data = await response.json();
          products = data;
        } else {
          throw new Error('Error en la solicitud');
        }
      })
      .catch((error) => {
        console.error(error);
      });

    if (products.length > 0) {
      const product = products[0];
      addProductToCart(product);
      setSearchedProduct('');
    } else {
      toast.info('Código o nombre inválido', { hideProgressBar: true, autoClose: 2000 });
    }
  }

  useEffect(() => {
    getSearchProduct(); // eslint-disable-next-line
  }, []);
  useEffect(getSearchProduct, [searchedProduct]);

  const productContainerStyles = (product) => {
    
    let styles = [];
    
    if (product.stock > 0) {
      styles.push('sales-grid-item')
    } else {
      styles.push('sales-grid-item gray-bg');
    }

    if (product.isExact) {
      styles.push('sales-product-focus');
    }

    return styles.join(' ');
  }

  return (
    <div className="parentSales">

      <Navbar />

      <div className='sales-container'>
        {
          // Initial stage
          payingState === 0 ?

            <div className='cart-receipt'>

              <div className='product-list-container shadow-div'>

                <h3 className='sales-title'>Carrito de compras</h3>

                <div className='sales-grid-box'>
                  <div className='sales-grid-container'>
                    {
                      products.map(product =>
                        <div className={productContainerStyles(product)}
                          key={product.product_id}
                          onClick={() => addProductToCart(product)}
                          title={product.name}
                        >
                          <div className='sales-grid-item-img-container'>
                            <img src={`${BACK_END}/product/image/${product?.image ?? 'default-image.png'}`} alt={product.name} loading='lazy' />
                          </div>
                          <div>
                            <p>
                              {product.name}
                              {(product.stock <= 0) ? <span className='sales-no-stock'><br />(Sin stock)</span> : <></>}
                            </p>
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
                <div className="search-register-container">

                  <div className='search'>
                    <FaSearch className='search__icon' size={26} color='000' />
                    <input
                      autoFocus
                      className='search__input'
                      value={searchedProduct}
                      type='text'
                      placeholder='Buscar'
                      onChange={(event) => setSearchedProduct(event.target.value)}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          addSearchedProductToCart();
                        }
                      }}
                      onBlur={e => {
                        if (e.relatedTarget === null || e.relatedTarget.classList.contains('sale-pad-button')) {
                          e.target.focus();
                        }
                      }}
                    />
                  </div>
                  <div className='sales-button-left-row'>
                    <button className='charge sales-button-print-last' onClick={openCashWithdrawalModal}><FaMoneyBillTransfer /> Retiro</button>
                    <button className='charge sales-button-print-last' onClick={openCashDepositModal}><FaMoneyBillTransfer /> Entrada</button>
                  </div>
                </div>
              </div>
              <div className="receipt-container shadow-div">
                <Receipt
                  stage={payingState}
                  setPayingState={setPayingState}
                />
              </div>
            </div>
            :
            // Final stage
            <div className='receipt-register'>
              <div className="receipt-container-checkout">
                <Receipt
                  setPayingState={setPayingState}
                />
              </div>
              <div className="register-container">
                <Register
                  setPayingState={setPayingState}
                  afterSell={afterSell}
                />
              </div>
            </div>
        }

        <CashWithdrawalModal
          isCashWithdrawalModalOpen={isCashWithdrawalModalOpen}
          closeCashWithdrawalModal={closeCashWithdrawalModal}
        />

        <CashDepositModal
          isCashDepositModalOpen={isCashDepositModalOpen}
          closeCashDepositModal={closeCashDepositModal}
        />

      </div>
    </div>
  )
}

export default Sales;